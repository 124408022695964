<template>
	<div>
		<addressModel :open="addOpen" :defaultForm="addressForm" @close="addCancel" @ok="submitForm"></addressModel>
		<div class="table-page-search-wrapper" style="margin-bottom: 10px;padding: 20px;">
			<AdvanceTable :columns="columns" :data-source="list" :title="tableTitle" :loading="loading" rowKey="id"
				:scroll="{ x: 1300 }" size="middle" tableKey="view-text-table" @change="handleTableChange"
				@refresh="getMyAddList" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,fixed:true }"
				:format-conditions="true" :pagination="{
			  current: queryParam.pageNum,
			  pageSize: queryParam.pageSize,
			  total: total,
			  showSizeChanger: true,
			  showLessItems: true,
			  showQuickJumper: true,
			  showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条，总计 ${total} 条`,
			  onChange: changeSize,
			  onShowSizeChange: onShowSizeChange,
			}">
				<span slot="economize" slot-scope="{text, record}">
					{{record.province}}/{{record.city}}/{{record.region}}
				</span>
				<span style="display: flex;" slot="isdefault" slot-scope="{text, record}">
					<span v-if="record.isdefault==1" style="color:red">[默认地址]</span>
				</span>
				<span slot="operation" slot-scope="{text, record}">
					<a @click="modifyAddress(record)" style="color: #2f54eb;">
						修改
					</a>
					<a-divider type="vertical" />
					<a @click="deleteValue(record)" style="color: #2f54eb;">
						删除
					</a>
					<a-divider type="vertical" v-if="record.isdefault!==1" />
					<a @click="defaultValue(record)" style="color: #2f54eb;" v-if="record.isdefault!==1">
						设为默认值
					</a>
				</span>
				<div class="table-operations" slot="button">
					<c-button type="warning" :height="33" style="margin-left: 10px;" @click="newAddress"><a-icon type="plus" style="padding-right: 5px;" />新增</c-button>
				</div>
			</AdvanceTable>
		</div>
	</div>
</template>

<script>
	import {
		getUser,
		updateUser,
		updatepword,
		getMyAddList,
		defaultAdd,
		updateAdd
	} from '@/api/userInfo/index'
	
	import AdvanceTable from '@/components/pt/table/AdvanceTable'//table组件引入
	import CButton from '@/components/CButton/index.vue'//button组件引入
	import addressModel from './components/addressModel.vue'
	import Cookies from "js-cookie";
	export default {
		components: {
			AdvanceTable,
			addressModel,
			CButton
		},
		data() {
			return {
				userInfo: null, //获取用户信息接口
				// 表单参数
				form: {
					id:undefined,//用户编号
					qq:undefined,//qq号码
					email:undefined,//邮箱
					uid:undefined,//用户编号
					pword:undefined,//新密码
					confirmpwd:undefined,//确认密码
					smscode:undefined,//验证码
					mobile:undefined,//手机号
				},
				// 地址接口传参
				queryParam: {
					pageNum: 1,
					pageSize: 10,
					uid: undefined,
					isdelete:0
				},
				//更改地址参数
				addressForm: {
					id: undefined,//id
					userid: undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					id: undefined,//id
					type: undefined, //是否默认
				},
				//新增地址弹框
				addOpen: false,
				//列表数据
				list: [],
				//table
				columns: [{
						title: 'No.',
						dataIndex: 'id',
						width: 120,
					},
					{
						title: '收货人',
						dataIndex: 'contactuser',
						width: 150,
					},
					{
						title: '收货人手机',
						dataIndex: 'contacttel',
						width: 200,
					},
					{
						title: '身份/城市/区', 	
						dataIndex: 'economize',
						width: 150,
						scopedSlots: {
							customRender: 'economize'
						},
					},
					{
						title: '收货地址',
						dataIndex: 'address',
						width: 150,
						scopedSlots: {
							customRender: 'address'
						},
					},
					{
						title: '默认地址',
						dataIndex: 'isdefault',
						width: 150,
						scopedSlots: {
							customRender: 'isdefault'
						},
					},
					{
						title: '操作',
						dataIndex: 'operation',
						width: 250,
						fixed: 'right',
						scopedSlots: {
							customRender: 'operation'
						},
						align: 'center'
					}
				],
				//table名
				tableTitle: '地址管理',
				loading: false,
				//总数量
				total: 0,
				//选中数组
				selectedRowKeys: [],
			}
		},

		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
				this.form.mobile = this.userInfo.contacttel
			}
			this.getUser()
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//--------------获取用户信息数据----------------------
			getUser: function() {
				let data = {
					id: this.userInfo.id
				}
				getUser(data).then(res => {
					this.userInfo = res.data
					this.form.qq = res.data.qq
					this.form.email = res.data.email
					this.form.id = res.data.id
					this.form.uid = res.data.id
					this.queryParam.uid=res.data.id
					sessionStorage.setItem("userInfo", JSON.stringify(this.userInfo))
					
					this.getMyAddList()
				})
			},
			
			//------------------查询用户收货地址接口---------------------
			getMyAddList:function(){
				getMyAddList(this.queryParam).then(res=>{
					this.list=res.data.content
				})
			},
			
			getAddList:function(){
				
			},
			
			//---------------选择数量---------------------------
			changeSize: function(current, pageSize) {
				this.queryParam.pageNum = current
				this.queryParam.pageSize = pageSize
				console.log("changeSize")
			},
			
			//--------------选择分页-----------------------------
			onShowSizeChange: function(current, pageSize) {
				this.queryParam.pageSize = pageSize
				console.log("onShowSizeChange")
			},
			
			//-----------------多选/选择表格------------------------------
			onSelectChange(selectedRowKeys, selectedRows) {
				console.log("onSelectChange")
				this.selectedRowKeys = selectedRowKeys
			},
			handleTableChange: function(pagination, filters, sorter) {
			
			},
			
			//---------------关闭弹框------------------------------
			addCancel: function() {
				this.addressForm = {
					id: undefined,//id
					userid: undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: undefined, //是否默认
				}
				this.addOpen = false;
			},
			
			//-----------------确认地址弹框回调----------------------
			submitForm: function() {
				this.addressForm = {
					id: undefined,//id
					userid: undefined,//uid
					province: undefined, //省
					city: undefined, //市
					area: undefined, //取
					streetAdd: undefined, //街道地址
					contacts: undefined, //联系人
					phone: undefined, //联系电话
					company: undefined, //公司名
					text: undefined, //合成字符串
					type: undefined, //是否默认
				}
				this.addOpen=false;
				this.getMyAddList()
			},
			
			//---------------打开修改地址弹框-----------------------
			modifyAddress:function(event){
				// console.log(event)
				this.addressForm.id=event.id
				this.addressForm.userid=event.uid
				this.addressForm.province=event.province
				this.addressForm.city=event.city
				this.addressForm.area=event.region
				this.addressForm.streetAdd=event.address
				this.addressForm.contacts=event.contactuser
				this.addressForm.phone=event.contacttel
				this.addOpen=true;
			},
			
			//-----------------------修改密码------------------------
			changePassword:function(){
				if(this.form.pword==undefined){
					this.$message.error('请输入新密码')
				}else if(this.form.confirmpwd==undefined){
					this.$message.error('请输入再次输入新密码')
				}else if(this.form.pword!==this.form.confirmpwd){
					this.$message.error('请再次输入的密码和新密码一致')
				}else if(this.form.smscode==undefined){
					this.$message.error('请输入验证码')
				}else{
					updatepword(this.form).then(res=>{
						this.form.pword=undefined
						this.form.confirmpwd=undefined
						this.$message.success('修改密码成功')
					})
				}
			},
			
			//--------------------打开新增地址----------------------
			newAddress:function(){
				this.addressForm.userid=this.userInfo.id
				this.addOpen=true;
			},
			
			//-------------------设置默认值----------------------
			defaultValue:function(event){
				let data ={
					id:event.id,
					uid:event.userid
				}
				defaultAdd(data).then(res=>{
					this.$message.success('设置默认地址成功')
					this.getMyAddList();
				})
			},
			
			//-----------------删除对应数据-----------------------
			deleteValue:function(event){
				this.$confirm('是否删除该地址？', '删除地址', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  type: 'warning'
				}).then(() => {
				  let data={
				  	id:event.id,
				  	uid:event.uid,
				  	isdelete:1,
				  }
				  updateAdd(data).then(res=>{
				  	this.$message.success(
				  		'修改成功',
				  	)
					this.getMyAddList();
				  })
				}).catch(() => {});
			}
		}
	}
</script>

<style lang="less">

</style>