import request from '@/utils/request'

//查询用户信息
export function getUser(data) {
	return request({
		url: '/orderInfo/getUser',
		method: 'get',
		params: data
	})
}

//修改用户信息
export function updateUser(data) {
	return request({
		url: '/orderInfo/updateUser',
		method: 'post',
		data: data
	})
}

//修改用户密码
export function updatepword(data) {
	return request({
		url: '/user/updatepword',
		method: 'post',
		data: data
	})
}

//查询用户收货地址接口
export function getMyAddList(data) {
	return request({
		url: '/orderInfo/getMyAddList',
		method: 'post',
		data: data
	})
}

//修改用户收货地址接口
export function updateAdd(data) {
	return request({
		url: '/orderInfo/updateAdd',
		method: 'post',
		data: data
	})
}

//新增用户收货地址接口
export function saveAddress(data) {
	return request({
		url: '/orderInfo/saveAddress',
		method: 'post',
		data: data
	})
}

//用户收货地址设置默认接口
export function defaultAdd(data) {
	return request({
		url: '/orderInfo/defaultAdd',
		method: 'get',
		params: data
	})
}