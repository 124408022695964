import AntModal from '@/components/pt/dialog/AntModal' //Modal组件引入
import CButton from '@/components/CButton/index.vue' //button组件引入
import {
		updateAdd,
		saveAddress
	} from '@/api/userInfo/index'
export default {
	components: {
		AntModal,
		CButton
	},
	props: {
		open: { // 按钮默认文本
			type: Boolean,
			default: false
		},
		defaultForm:{
			type:Object,
			default:()=>{}
		},
	},
	data() {
		return {
			modalOpen: this.open, //弹窗打开关闭
			formTitle: "修改产品", //弹框名
			customStyle: 'background: #fff;border-radius: 4px;margin-bottom: 24px;border: 0;overflow: hidden;',
			// 表单参数
			form: {
				id: undefined,//id
				userid: undefined,//uid
				province: undefined,//省
				city: undefined,//市
				area: undefined,//取
				streetAdd: undefined,//街道地址
				contacts: undefined,//联系人
				phone: undefined,//联系电话
				company: undefined,//公司名
				text: undefined,//合成字符串
				type: undefined,//是否默认
			},
			// 表单验证条件
			rules: {
				area: [{
					required: true,
					message: '请选择地址',
					trigger: 'blur'
				}],
				streetAdd: [{
					required: true,
					message: '请输入街道地址',
					trigger: 'blur'
				}],
				contacts: [{
					required: true,
					message: '请输入联系人',
					trigger: 'blur'
				}],
				phone: [{
						required: true,
						trigger: "blur",
						message: "请输入您的联系电话"
					},
					{
						pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
						message: "请输入正确的手机号码",
						trigger: "blur"
					}],
				company: [{
					required: false,
					message: '请输入公司名',
					trigger: 'blur'
				}],
				address: [{
					required: false,
					message: '请输入公司名',
					trigger: 'blur'
				}]
			},
			radioStyle: {
				display: 'block',
				height: '30px',
				lineHeight: '30px',
			},
		}
	},

	created() {

	},

	mounted() {

	},

	watch: {
		open(val){
			this.form=this.defaultForm
		}
	},

	computed: {

	},

	methods: {
		//----------------------是否默认----------------------
		onChange:function(e){
			if(this.form.type==1){
				this.form.type=undefined
			}else{
				this.form.type=1
			}
			console.log(this.form.type)
		},
		
		//------------------------取消按钮-------------------------------
		cancel: function() {
			// console.log("cancel")
			this.modalOpen = false;
			this.$emit('close');
		},
		
		//------------------选择地区--------------------------------
		onChangeProvince(e) {
			console.log(e)
			this.form.province=e.value
		},
		onChangeCity(e) {
			console.log(e)
			this.form.city=e.value
		},
		onChangeArea(e) {
			console.log(e)
			this.form.area=e.value
		},

		//------------------------提交按钮--------------------------------
		submitForm: function() {
			this.$refs.form.validate(valid => {
				if (valid) {
					if(this.form.company!==undefined){
						this.form.text = this.form.province + ' ' + this.form.city + ' ' + this.form.area + ' ' + this.form.streetAdd + ' ' + this.form.contacts + ' ' + this.form.phone + ' ' + this.form.company
					}else{
						this.form.text = this.form.province + ' ' + this.form.city + ' ' + this.form.area + ' ' + this.form.streetAdd + ' ' + this.form.contacts + ' ' + this.form.phone
					}
					this.modalOpen = false
					this.$confirm('是否保存修改的订单地址？', '修改订单', {
					  confirmButtonText: '确定',
					  cancelButtonText: '取消',
					  type: 'warning'
					}).then(() => {
					  if(this.form.id){
						  let data={
						  	id:this.form.id,
						  	userid:this.form.userid,
						  	province:this.form.province,
						  	city:this.form.city,
						  	region:this.form.area,
						  	address:this.form.streetAdd,
						  	contactuser:this.form.contacts,
						  	contacttel:this.form.phone,
						  }
						  updateAdd(data).then(res=>{
						  	this.$message.success(
						  		'修改成功',
						  	)
						  	this.modalOpen = false
						  	this.$emit('ok')
						  })
					  }else{
						  let data={
							userid: this.form.userid,
						  	province:this.form.province,
						  	city:this.form.city,
						  	region:this.form.area,
						  	address:this.form.streetAdd,
						  	contactuser:this.form.contacts,
						  	contacttel:this.form.phone,
							isdefault:this.form.type
						  }
						  console.log(data)
						  saveAddress(data).then(res=>{
						  	if(res.code=="200"){
								this.$message.success(
									'新增成功',
								)
							}else{
								this.$message.error(
									'新增失败',
								)
							}
						  	this.modalOpen = false
						  	this.$emit('ok')
						  })
					  }
					}).catch(() => {});
				} else {
					return false
				}
			})
		}
	}
}
