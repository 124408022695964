<template>
	<div>
		<!-- 增加修改 -->
		<ant-modal :visible="open" :modal-title="formTitle" :adjust-size="true" modalHeight="480" modalWidth="640"
			@cancel="cancel">
			<a-form-model ref="form" :model="form" :rules="rules" slot="content" layout="vertical">
				<!-- 不可选填 -->
				<a-row class="form-row" :gutter="32">
					<a-col :lg="24" :md="24" :sm="24">
						<a-form-model-item label="选择地址" prop="area">
							<v-distpicker :province="form.province" :city="form.city" :area="form.area" @province="onChangeProvince" @city="onChangeCity" @area="onChangeArea"></v-distpicker>
						</a-form-model-item>
					</a-col>
					<a-col :lg="24" :md="24" :sm="24">
						<a-form-model-item label="街道地址" prop="streetAdd">
							<a-input v-model.trim="form.streetAdd" placeholder="请输入街道地址" />
						</a-form-model-item>
					</a-col>
					<a-col :lg="8" :md="8" :sm="24">
						<a-form-model-item label="联系人" prop="contacts">
							<a-input v-model.trim="form.contacts" placeholder="请输入联系人" />
						</a-form-model-item>
					</a-col>
					<a-col :lg="16" :md="16" :sm="24">
						<a-form-model-item label="联系电话" prop="phone">
							<a-input v-model.trim="form.phone" placeholder="请输入电话" />
						</a-form-model-item>
					</a-col>
					
					<a-col :lg="24" :md="24" :sm="24" v-if="!form.id">
						<a-form-model-item label="默认地址" prop="address">
							<a-radio-group v-model="form.type">
							    <a-radio :style="radioStyle" :value="1" @click.native.prevent="onChange">
							      设置默认地址
							    </a-radio>
							</a-radio-group>
						</a-form-model-item>
					</a-col>
				</a-row>

				<!-- 可选填 -->
				<!-- <a-collapse :bordered="false" expandIconPosition="left">
					<template #expandIcon="props">
						<a-icon type="caret-right" :rotate="props.isActive ? 90 : 0" />
					</template>
					<a-collapse-panel key="1" header="填写更多信息（可选)" :style="customStyle">
						<a-row :gutter="32">
							<a-col :lg="32" :md="32" :sm="32">
								<a-form-model-item label="公司名" prop="company">
									<a-input v-model.trim="form.company" placeholder="请输入公司名" />
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-collapse-panel>
				</a-collapse> -->
			</a-form-model>
			<template slot="footer">
				<a-button type="primary" @click="submitForm">
					保存
				</a-button>
				<a-button @click="cancel">
					取消
				</a-button>
			</template>
		</ant-modal>
	</div>
</template>

<script>
	import addressModel from './addressModel'
	export default {
		...addressModel
	}
</script>

<style lang="less" scoped>
	
</style>
