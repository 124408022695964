var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('addressModel',{attrs:{"open":_vm.addOpen,"defaultForm":_vm.addressForm},on:{"close":_vm.addCancel,"ok":_vm.submitForm}}),_c('div',{staticClass:"table-page-search-wrapper",staticStyle:{"margin-bottom":"10px","padding":"20px"}},[_c('AdvanceTable',{attrs:{"columns":_vm.columns,"data-source":_vm.list,"title":_vm.tableTitle,"loading":_vm.loading,"rowKey":"id","scroll":{ x: 1300 },"size":"middle","tableKey":"view-text-table","row-selection":{ selectedRowKeys: _vm.selectedRowKeys, onChange: _vm.onSelectChange,fixed:true },"format-conditions":true,"pagination":{
		  current: _vm.queryParam.pageNum,
		  pageSize: _vm.queryParam.pageSize,
		  total: _vm.total,
		  showSizeChanger: true,
		  showLessItems: true,
		  showQuickJumper: true,
		  showTotal: function (total, range) { return ("第 " + (range[0]) + "-" + (range[1]) + " 条，总计 " + total + " 条"); },
		  onChange: _vm.changeSize,
		  onShowSizeChange: _vm.onShowSizeChange,
		}},on:{"change":_vm.handleTableChange,"refresh":_vm.getMyAddList},scopedSlots:_vm._u([{key:"economize",fn:function(ref){
		var text = ref.text;
		var record = ref.record;
return _c('span',{},[_vm._v(" "+_vm._s(record.province)+"/"+_vm._s(record.city)+"/"+_vm._s(record.region)+" ")])}},{key:"isdefault",fn:function(ref){
		var text = ref.text;
		var record = ref.record;
return _c('span',{staticStyle:{"display":"flex"}},[(record.isdefault==1)?_c('span',{staticStyle:{"color":"red"}},[_vm._v("[默认地址]")]):_vm._e()])}},{key:"operation",fn:function(ref){
		var text = ref.text;
		var record = ref.record;
return _c('span',{},[_c('a',{staticStyle:{"color":"#2f54eb"},on:{"click":function($event){return _vm.modifyAddress(record)}}},[_vm._v(" 修改 ")]),_c('a-divider',{attrs:{"type":"vertical"}}),_c('a',{staticStyle:{"color":"#2f54eb"},on:{"click":function($event){return _vm.deleteValue(record)}}},[_vm._v(" 删除 ")]),(record.isdefault!==1)?_c('a-divider',{attrs:{"type":"vertical"}}):_vm._e(),(record.isdefault!==1)?_c('a',{staticStyle:{"color":"#2f54eb"},on:{"click":function($event){return _vm.defaultValue(record)}}},[_vm._v(" 设为默认值 ")]):_vm._e()],1)}}])},[_c('div',{staticClass:"table-operations",attrs:{"slot":"button"},slot:"button"},[_c('c-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"warning","height":33},on:{"click":_vm.newAddress}},[_c('a-icon',{staticStyle:{"padding-right":"5px"},attrs:{"type":"plus"}}),_vm._v("新增")],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }